import React from 'react';
import { SEO } from 'components/SEO';
import { MainBlock } from './MainBlock';
import { CreditCardBlock } from './CreditCardBlock';
import './styles.scss';

const Payments = () => (
  <main className="page payments-page">
    <SEO
      title="Оплата"
      description="Оплатить курсы: программирования, шахмат, математики в нашей онлайн-школе, можно любым удобным для вас способом."
    />
    <MainBlock />
    <CreditCardBlock />
  </main>
);

export default Payments;
