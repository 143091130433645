import React from 'react';
import { routes } from 'routes';
import { Link } from 'gatsby';
import chessPayImg from './images/chess_pay.png';
import programmingPayImg from './images/programming_pay.png';

export const CreditCardBlock = () => (
  <section className="credit-card-block">
    <h2 className="title">Оплата банковской картой</h2>
    <div className="subtitle">Выберите, что хотите оплатить</div>
    <div className="credit-card-row row">
      <div className="col col-12 col-xl">
        <a href="https://chess-up.school/chess-pay">
          <div className="credit-card-card">
            <img src={chessPayImg} alt="Оплата шахмат" />
          </div>
        </a>
        <h4 className="title">Шахматы</h4>
      </div>
      <div className="col col-12 col-xl">
        <Link to={routes.programmingPay.path}>
          <div className="credit-card-card">
            <img src={programmingPayImg} alt="Оплата программирование" />
          </div>
        </Link>
        <h4 className="title">Программирование</h4>
      </div>
    </div>
  </section>
);
