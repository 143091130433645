import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import bankImg from './images/bank.svg';
import moneyImg from './images/money.svg';
import pinImg from './images/pin.svg';
import creditCardImg from './images/credit_card.svg';

export const MainBlock = () => (
  <section className="main-block container">
    <div className="background-top" />
    <div className="main-proposition">
      <h1 className="title">Данные для оплаты</h1>
      <div className="payment-list">
        <div className="payment-item">
          <img src={bankImg} alt="Банк" className="payment-img" />
          <div className="payment-details">
            <h4 className="payment-title">Расчетный счет</h4>
            <div className="payment-text">№ BY75TECN30131240900000000000</div>
          </div>
        </div>
        <div className="payment-item">
          <img src={moneyImg} alt="Деньги" className="payment-img" />
          <div className="payment-details">
            <h4 className="payment-title">Валютный счет (USD)</h4>
            <div className="payment-text">№ BY77TECN30131240920110000000</div>
          </div>
        </div>
        <div className="payment-item">
          <img src={pinImg} alt="Указатель" className="payment-img" />
          <div className="payment-details">
            <h4 className="payment-title">Банковские реквизиты</h4>
            <div className="payment-text">
              ОАО "Технобанк", г. Минск ул. Кропоткина 44, код TECNBY22
            </div>
          </div>
        </div>
        <div className="payment-item">
          <img src={creditCardImg} alt="Дом" className="payment-img" />
          <div className="payment-details">
            <h4 className="payment-title">Оплата банковской картой</h4>
            <div className="payment-text">
              Для оплаты выберите одно из направлений ниже нажав на
              соответствующий блок в разделе "Оплата банковской картой". Затем
              выберите подходящий для вас пакет занятий и нажмите "Оплатить".
              Оплата производится через сервис Webpay.
            </div>
          </div>
        </div>
      </div>
    </div>
    <BackgroundImg />
  </section>
);
