import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled';

const BackgroundImgStyled = styled(BackgroundImage)`
  background-size: contain;
  width: 100%;
  max-width: 594px;
  margin-left: auto;
`;

export const BackgroundImg = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            relativePath: {
              eq: "screens/Payments/MainBlock/images/background_main.png"
            }
          ) {
            childImageSharp {
              fluid(maxWidth: 1920, base64Width: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImgStyled
            critical
            className="background-main"
            Tag="div"
            fluid={imageData}
          />
        );
      }}
    />
  );
};
